@import 'components/fonts';

@import 'config';
@import 'base';
@import 'reset';
@import 'type-scale';
@import 'line-height';
@import 'type';
@import 'skins';
@import 'theme';

@import 'components/buttons';
@import 'components/icon';
@import 'components/pagination';
@import 'components/animation';
@import 'components/post';
@import 'components/code-highlight';
@import 'components/logo';
@import 'components/section';
@import 'components/links';
@import 'components/lazy';
@import 'components/tags';

.hide {
    display: none;
}
