.icon {
    padding: 0.5rem;
    font-size: 1.25rem;
    text-decoration: none;
}

.ion-icon {
    width: 1.25rem;
    height: 1.25rem;
    transition: all 0.3s ease;
}

.icon:hover {
    color: slategrey;
    transition: all 0.3s ease;
}

.button.prev .icon {
    padding: 0.5rem 0.5rem 0.5rem 0rem;
}

.button.next .icon {
    padding: 0.5rem 0rem 0.5rem 0.5rem;
}

.button:hover {
    background-color: $grey;
}
