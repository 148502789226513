.button {
    appearance: none;
    border: 1px solid $border;
    background-color: $white;
    border-radius: 3px;
    padding: 0.5rem 1rem;
    cursor: pointer;
    display: inline-block;
    color: $black;
    font-family: $title-font;
}

.button.is-disabled {
    background-color: $grey;
    opacity: 0.5;
}
