.theme-letter {
    background: slategrey;
    color: $white;

    .title {
        color: $white;
    }
    .meta {
        color: $grey;
    }

    ~ .section blockquote {
        border-left-color: slategrey;
    }
}

.theme-project {
    background: #64748b;
    color: $white;

    .title {
        color: $white;
    }
    .meta {
        color: $grey;
    }

    ~ .section blockquote {
        border-left-color: #0f172a;
    }

    .button {
        background-color: #0f172a;
        border-color: #0f172a;
        color: white;

        &:hover,
        &:focus {
            background-color: $black;
        }
    }
}

.theme-dark {
    background-color: $black;
    color: $white;

    .title {
        color: $white;
    }
    .meta {
        color: $grey;
    }

    .content a {
        color: $white;
        border-color: $white;
    }

    .theme-letter {
        background-color: darkslategrey;
    }

    .post {
        border-bottom-color: #333;
    }
}

@media screen and (prefers-color-scheme: dark) {
    body {
        background-color: $black;
        color: $white;

        .title {
            color: $white;
        }
        .meta {
            color: $grey;
        }

        .content a {
            color: $white;
            border-color: $white;
        }

        .theme-letter {
            background-color: darkslategrey;
        }

        .post {
            border-bottom-color: #333;
        }

        code {
            background-color: #1c1c1c;
        }
        .hljs {
            background-color: #111;
        }

        .logo {
            background-color: #111;
        }
        .button {
            background-color: #333;
            color: $white;
            border-color: #000;

            &:hover {
                background-color: #111;
            }
        }
    }
}
