.lazy {
    display: inline-block;
    position: relative;
    width: 100%;

}

.lazy img {
    opacity: 0;
    transition: opacity .3s ease;

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.lazy img.lazy-loaded {
    opacity: 1;
    transition: opacity .3s ease;
}
