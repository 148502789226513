$white: #fff;
$black: #1c1c1c;

$darker-grey: #9fa2a8;

$border: #d3d6db;
$grey: #f5f7fa;

$title-font: 'Lora', serif;
$body-font: 'Lora', serif;
$monospace: 'Fira Code', monospace;
$line-height: 1.25;
$font-size: 16px;

$bg: $white;

$blue: #2077b2;
$primary: $blue;
$grey-dark: #42464c;
