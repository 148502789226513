.blink {
    animation: blink 1s infinite;
}

@keyframes blink {
    0%,100% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }
}


