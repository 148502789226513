.logo {
    color: $black;
    text-decoration: none;
    display: inline-block;
    background-color: #444;
    color: #fff;
    text-align: center;
    padding: .33rem .9rem .66rem;
    border-radius: 3px;
}

.logo span {
    line-height: 1;
    margin-top: .1rem;
    margin-bottom: -.15rem;
    font-family: $title-font;
    display: inline-block;
}
