.section {
    padding: 2rem 1.5rem;
    display: inline-block;
    width: 100%;
}

.header {
    padding: 1rem 2rem;
    display: inline-block;
    width: 100%;

    @media (min-width: 600px) {
        padding: 4rem 2rem 2rem;
    }
}

.main-header {
    padding: 1rem;

    .nav {
        float: right;
        display: flex;

        a {
            padding: 0.75rem 1rem;
            font-weight: bold;
        }
    }

    button {
        border: none;
        background-color: transparent;
        float: right;
        cursor: pointer;
        color: inherit;

        svg {
            width: 40px;
            height: 40px;
            padding: 10px 0;
        }
    }
}
